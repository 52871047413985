import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Front Squat 4×6\\@80% 1RM`}</p>
    <p>{`Single Leg Bent Leg Bridge 4×6/leg`}</p>
    <p>{`then,`}</p>
    <p>{`5 Rounds for time of:`}</p>
    <p>{`9-Front Squats (135/95)(RX+ 155/105)`}</p>
    <p>{`7-Power Cleans`}</p>
    <p>{`5-Hang Squat Cleans`}</p>
    <p><em parentName="p">{`*`}{`scale to 65% Power Clean max`}</em></p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`If you haven’t already please find the Crossfittheville Facebook
page and the private group created for Ville members.  We will be
sending at home workouts through this group.  If you cannot get on
Facebook please let us know and we will email them to you.`}</em></strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      